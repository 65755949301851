import Swiper, {Navigation, Pagination, Autoplay} from 'swiper'
Swiper.use([Navigation, Pagination, Autoplay]);

export default function sliders() {
    (function($) {
        $(window).on('load', function () {
           // get all sliders, we need to loop them due to different settings + nav
           var swipers = document.querySelectorAll('.testimonials-swiper');
           swipers.forEach(function(el,index){
            var closestSection = el.closest('section');
            var controls = closestSection.querySelector('.control');

                // slider settings
                var options = {
                    speed: 600,
                    loop: true,
                    //autoHeight: true,
                    spaceBetween: 7,
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: true,
                        },
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        },
                        pagination: {
                            el: '.swiper-pagination',
                            clickable: true,
                        },
                        breakpoints: {
                            640 : {
                                slidesPerView: 2,
                                spaceBetween: 15,
                            },
                            992: {
                                loop: true,
                                slidesPerView: 2,
                                spaceBetween: 18,
                                autoHeight: true,
                            }
                        }
                    };


                // init slider
                new Swiper(el, options);
            });
       })


    })( jQuery );

    (function($) {
        $(window).on('load', function () {
           // get all sliders, we need to loop them due to different settings + nav
           var swipers = document.querySelectorAll('.gallery-swiper');
           swipers.forEach(function(el,index){
            var closestSection = el.closest('section');
            var controls = closestSection.querySelector('.control');

                // slider settings
                var options = {
                    loop: true,
                     slidesPerView: "auto",
                    //autoHeight: true,
                    spaceBetween: 6,
                    autoplay: {
                        delay: 1500,
                        disableOnInteraction: true,
                        },
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        },
                        pagination: {
                            el: '.swiper-pagination',
                            clickable: true,
                        },
                        breakpoints: {
                            640 : {
                                slidesPerView: "auto",
                                spaceBetween: 10,
                            },
                            992: {
                                slidesPerView: "auto",
                                spaceBetween: 10,
                            }
                        }
                    };


                // init slider
                new Swiper(el, options);
            });
       })


    })( jQuery );
}

